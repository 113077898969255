<template>
  <Modal :disableCloseOnClickAway="true" :closePosition="'hidden'">
    <div class="editorModal p-9">
      <div
        v-if="loading !== 'false'"
        class="
          w-full
          h-full
          flex
          justify-center
          items-center
          editor-loading-overlay
          absolute
          left-0
          top-0
        "
      >
        <div class="bg-white rounded-lg p-2 text-center shadow-sm">Loading...</div>
      </div>
      <div class="w-full">
        <h1
          v-if="!onboardingState || onboardingState === 'delete'"
          class="
            flex-grow
            overflow-hidden
            text-3xl
            font-semibold font-h
            whitespace-nowrap
            overflow-ellipsis
            sp-0
            py-2.5
          "
          :class="{ 'text-primary-300': !title }"
        >
          {{ title || "Name Your Doc" }}
        </h1>
        <div
          v-else-if="onboardingState === 'initial'"
          class="
            mb-4
            text-xs text-primary-100
            px-3
            py-2.75
            rounded-lg
            bg-primary
            inline-block
            w-full
          "
        >
          <span>💡</span>
          <span>{{ onboardingMessages[onboardingState] }}</span>
        </div>
        <div
          v-else-if="onboardingState === 'exit'"
          class="
            mb-4
            text-xs text-white
            px-3
            py-2.75
            rounded-lg
            bg-red
            inline-block
            w-full
          "
        >
          <span>💡</span>
          <span>{{ onboardingMessages[onboardingState] }}</span>
          <span class="font-bold">
            <button @click="() => exitConfirm(true)">Yes</button> |
            <button @click="() => exitConfirm(false)">No</button>
          </span>
        </div>
        <div class="playerContainer">
          <div
            class="overflow-y-auto"
            :style="{ 'max-height': height }"
            id="editorContainer"
            ref="editorContainer"
          >
            <div id="proseText">
              <ProseEditor
                ref="prose"
                :autocomplete="spellcheck"
                :autocorrect="spellcheck"
                :autocapitalize="spellcheck"
                :spellcheck="spellcheck"
                :owner="ownerId"
                :podId="podId"
                :isAutoScroll="true"
                @json="setDOCC"
                @sources="updateSources"
              />
            </div>
            <AudioController
              ref="audioController"
              :loading="loading"
              :urlList="urlList"
              :videoUrlList="videoUrlList"
              :imageUrlList="imageUrlList"
              :playerType="playerType"
            />
          </div>
          <div
            class="
              relative
              flex flex-col
              items-center
              justify-center
              thumbnail
              group
              m-2
            "
          >
            <VideoPlayer
              v-show="previewVideoStatus"
              v-if="podcastInfo"
              :subtitle="
                podcastInfo.hasOwnProperty('subtitle') && podcastInfo.subtitle
                  ? podcastInfo.subtitle
                  : subtitle
              "
              :debug="false"
              :autoplay="false"
              :subtitleStyle="null"
              :loading="loading === 'true' || !downloadedAssets"
              :scale="getScale"
              :type="'video'"
              :videoPlayerInfo="videoPlayerInfo"
              :zIndexClass="''"
              :showThumbnail="!playerDirty"
            />
            <div
              class="
                absolute
                top-0
                z-50
                flex
                items-center
                justify-center
                hidden
                w-full
                h-full
                group-hover:flex
              "
            >
              <div
                class="
                  absolute
                  text-lg
                  font-semibold
                  text-white
                  font-h
                  top-5
                  left-4
                "
              >
                {{ title }}
              </div>
              <div
                v-if="isPlaying"
                class="
                  flex
                  items-center
                  justify-center
                  text-white
                  rounded-full
                  cursor-pointer
                  bg-red
                  h-14
                  w-14
                "
                @click="toggleState"
              >
                <PauseIcon />
              </div>
              <div
                v-else
                class="
                  flex
                  items-center
                  justify-center
                  text-white
                  rounded-full
                  cursor-pointer
                  bg-red
                  h-14
                  w-14
                "
                @click="toggleState"
              >
                <PlayIcon />
              </div>

              <div class="absolute w-full px-4 bottom-2">
                <VueSlider
                  id="playerSeeker"
                  key="playerSeeker"
                  v-model="progressOutOf100"
                  style="height: 2px; padding: 0; width: 100%"
                  :railStyle="{
                    cursor: 'pointer',
                    'background-color': '#666C7C',
                  }"
                  :processStyle="{ 'background-color': '#fff' }"
                  :tooltip="'none'"
                  :interval="0.1"
                >
                  <template v-slot:dot="">
                    <div :class="'custom-dot'" />
                  </template>
                </VueSlider>
              </div>
            </div>
            <div
              v-show="onboardingState === 'delete'"
              class="
                absolute
                w-max
                -bottom-16
                left-1/2
                transform
                -translate-x-1/2
                text-xs text-primary-100
                px-3
                py-2.75
                rounded-lg
                bg-primary
                inline-block
              "
            >
              <span>💡</span>
              <span>{{ onboardingMessages[onboardingState] }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="absolute left-0 right-0 bottom-0">
        <div class="relative w-full editor-footer-overlay">
          <div class="absolute left-0 right-0 footerlay" />
          <div class="absolute left-0 right-0 footershadow" />
        </div>
        <Footer
          v-show="loading === 'false'"
          :playerLoadProgress="playerLoadProgress"
          :totalTime="totalTime"
          :isPlaying="isPlaying"
          :showAutopilotTooltip="false"
          :isOnboarding="true"
          @goToTime="goToTime"
          @toggleReduceNoise="toggleReduceNoise"
          @toggleState="toggleState"
          class="rounded-lg"
        />
      </div>
      <button
        v-show="loading !== 'true'"
        @click="
          () => {
            onboardingState = 'exit';
          }
        "
        class="
          rounded-full
          border-2 border-white
          text-white text-lg
          fixed
          -right-9
          w-7
          h-7
          top-0
          flex
          justify-center
          items-center
          font-semibold
          cursor-pointer"
      >
        X
      </button>
    </div>
  </Modal>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import CONSTANTS from '@/constants/CONSTANTS'
import ProseEditor from '@/view/voiceEditor/vue/components/NewProseEditor'
import Modal from '@/components/base/dialogs/Modal.vue'
import AudioController from '@/view/voiceEditor/vue/components/AudioController'
import VideoPlayer from '@/components/VideoPlayer/index.vue'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'
import { getPodcastMeta } from '@/services/api/podcast'
import { calculatePercentage } from '../../voiceEditor/vue/components/utility/utility'
import store from '@/services/store'
import { formatToProse } from '../../voiceEditor/vue/components/formatter'
import { formatAsSubtitleNew } from '@/utilities/utility'
import VueSlider from 'vue-slider-component'
import PlayIcon from '@/components/base/icons/Play.vue'
import PauseIcon from '@/components/base/icons/Pause.vue'
import EditorInit from '@/view/voiceEditor/editorUtil'
import { removeAllSelections } from '@/view/voiceEditor/proseEditor/util/editorSearchUtility'
// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from 'workerize-loader!../../../services/api/transcript' // workerize loader is a third party lib we use to get javascript inside the worker
let instance = worker()
let docc = null
export default {
  name: 'EditorModal',
  components: {
    ProseEditor,
    Modal,
    AudioController,
    VideoPlayer,
    VueSlider,
    PlayIcon,
    PauseIcon,
    Footer: () =>
      import(
        /* webpackChunkName: "Footer" */ '@/view/voiceEditor/vue/components/SpextDoc/footer/Footer.vue'
      )
  },
  data: function () {
    return {
      height: '350px',
      spellcheck: CONSTANTS.ENABLE_SPELL_CHECK,
      ownerId: CONSTANTS.newDemoUserId,
      podId: CONSTANTS.newDemoMediaId,
      urlList: {},
      videoUrlList: {},
      imageUrlList: {},
      loadingUrlList: {},
      editMode: 'editMedia',
      saving: '',
      playerDirty: false,
      intervalId: 0,
      podcastInfoListener: null,
      downloadedAssets: true,
      fileState: null,
      subtitle: [],
      loading: 'false',
      onboardingState: '',
      onboardingMessages: {
        initial: 'Till your file transcribes, try editing a sample file...',
        exit: 'Are you sure you want to exit the walkthrough? ',
        delete: "Select 'magically' and hit delete"
      },
      editorInit: null,
      fullscreen: false,
      deleteStateHappened: false
    }
  },
  computed: {
    ...{
      playerType() {
        return this.podcastInfo.hasOwnProperty('playerType')
          ? this.podcastInfo.playerType
          : 'video'
      },
      title: {
        get() {
          if (store.state.editor && store.state.editor.title) {
            return store.state.editor.title
          }
          return ''
        }
      },
      videoPlayerInfo() {
        const {
          backgroundImage,
          coverArt,
          subtitleStyle,
          enableSubtitleFormatting,
          heading,
          aspectRatio,
          template
        } = { ...this.podcastInfo }
        const info = {
          backgroundImage,
          coverArt,
          subtitleStyle,
          enableSubtitleFormatting,
          heading,
          aspectRatio,
          template
        }

        return info
      },
      overallProgress() {
        // logic to calculate % progress
        let vm = this
        if (this.podcastInfoExists) {
          return calculatePercentage(this.podcastInfo, vm.now)
        }
        return {
          progress: 0,
          status: {
            code: 100,
            text: 'Downloading'
          }
        }
      },
      getScale: function () {
        return window.innerWidth / 1920
      },
      progressOutOf100: {
        get() {
          const max = this.totalTime || 1000
          const min = 0
          const range = max - min
          if (range) {
            if (this.currentTime >= max) return 100
            return ((this.currentTime - min) / range) * 100
          }
          return 0
        },
        set(newVal) {
          const max = this.totalTime || 1000
          const min = 0
          const range = max - min
          if (range) {
            const seekedTime = min + (range * newVal) / 100
            this.goToTime(seekedTime)
          }
        }
      }
      // shouldShowDeleteState() {
      //   if (Math.floor(this.currentTime) === 8 && )
      // }
    },
    ...mapGetters({
      dirty: 'editor/dirty',
      timeDeleted: 'editor/timeDeleted',
      podcastInfo: 'editor/podcastInfo',
      isPlaying: 'editor/isPlaying',
      podcastInfoExists: 'editor/podcastInfoExists',
      user: 'app/user',
      currentTime: 'doc/currentTime',
      totalTime: 'editor/totalTime',
      playerLoadProgress: 'editor/playerLoadProgress',
      browserIs: 'app/browserIs',
      previewVideoStatus: 'video/previewVideoStatus',
      userOnboardingState: 'app/onboardingState'
    })
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal',
      closeModal: 'dialogs/closeModal'
    }),
    cleanupSelections: function() {
      removeAllSelections()
    },
    toggleReduceNoise() {
      let vm = this
      vm.$refs.prose.toggleReduceNoise()
      if (!vm.isPlaying) {
        setTimeout(function () {
          vm.toggleState(true)
        })
      }
    },
    setDOCC (json) {
      // console.log('json', json)
      docc = json
      store.commit('editor/setDirty', Date().toString())
      this.saveToFirebaseLater()
    },
    syncSubtitle: function () {
      if (myProseEditor) {
        this.subtitle = formatAsSubtitleNew(myProseEditor.view.state)
      }
    },
    async updateSources(sources, mediaSources) {
      const vm = this
      console.log('asdcsad', sources, vm.urlList)
      const srcs = await this.editorInit.updateSources(sources, mediaSources, vm.urlList)
      srcs.imageSources.forEach(value => {
        vm.$set(vm.imageUrlList, value.source, value.url)
      })
      srcs.videoSources.forEach(value => {
        vm.$set(vm.videoUrlList, value.source, value.url)
      })
      srcs.sources.forEach(value => {
        if (!value) return
        vm.$set(vm.urlList, value.source, value.url)
      })
    },
    async initPodcast() {
      /**
       * Fetch transcript from firebaase db
       */
      let vm = this
      vm.loading = 'true'
      // console.log(`initPodcast ${vm.user.uid} ${vm.podId} ${vm.$route.params.podId}`)
      console.time('initPodcast')
      // here await won't come as it is getting used in Promise.all
      // if getting await error, please rebuild
      this.podcastInfoListener = getPodcastMeta(vm.ownerId, vm.podId)
      this.editorInit = new EditorInit(vm.ownerId, true, false, vm.podcastInfo.uri, vm.browserIs)
      let flag = false
      this.podcastInfoListener.on('value', function (info) {
        let meta = info.val()
        console.log('THIS IS THE INFO', meta, info)
        if (!flag && meta) {
          flag = true

          // set mode, very important
          const mode = vm.editMode
          window.mode = mode
          store.commit('editor/setMode', mode)
          store.commit('editor/setTotalTime', meta.length)
          store.commit('editor/setTimeDeleted', meta.timeDeleted)
          store.commit('editor/setTitle', meta.title)
          store.commit('editor/setDocVersion', meta.version)
          if (meta.smartEditConfig) {
            console.log('smartedit exists')
            store.commit('smartEditConfig/setSmartEditConfig', meta.smartEditConfig)
          } else {
            console.log('smartedit empty')
            store.dispatch('smartEditConfig/initialiseSmartEditConfig', {
              podId: vm.podId,
              ownerId: vm.ownerId
            })
          }
        }
        store.commit('editor/setPodcastInfo', meta)
      })

      let textTranscript = instance.getTranscriptCorrected(
        vm.ownerId,
        vm.podId
      )

      try {
        let transcript = await textTranscript

        // used for transcript-corrected
        // as its html parser cannot be moved to a worker
        if (typeof transcript === 'string') {
          transcript = formatToProse(transcript, vm.podId)
        }
        transcript = await instance.addUIDToSource(transcript, vm.ownerId) // adding source ( audio )
        vm.loadJSON(transcript) // load inside prosemirror'
        console.timeEnd('initPodcast')
      } catch (err) {
        console.error('HERE ERROR', err)
        // when we are still waiting for the transcript
        vm.loading = 'Error'
        vm.podcastDeleted = true
        vm.startClock() // show progress as a circular bar
        console.log(err)

        // dont delete message
        // used by export server as flag to stop
        console.log('unable to load file')
        let anchor = document.createElement('a')
        anchor.setAttribute('id', 'downloadLink')
        anchor.setAttribute('err', 'yes')
        document.body.appendChild(anchor)
      }
    },
    loadJSON(json) {
      console.log('GETTING HERE')
      var vm = this
      // console.log('attr', json['attrs']'volume'])
      if (json['attrs'] && json['attrs']['volume']) {
        store.commit('editor/setMasterVolume', json['attrs']['volume'])
      }
      console.time('loadJSON')

      docc = json
      vm.$refs.prose.setContent(docc)
      vm.downloadedAssets = false
      console.timeEnd('loadJSON')
    },
    startClock() {
      let vm = this
      vm.intervalId = setInterval(function () {
        vm.now = Date.now()
        if (
          (vm.overallProgress.progress === 100 ||
            vm.overallProgress.status.code >= 400) &&
          vm.intervalId
        ) {
          console.log('stopping timer')
          clearInterval(vm.intervalId)
          if (vm.overallProgress.progress === 100) {
            vm.initPodcast()
          }
        }
      }, 1000)
    },
    switchOnListening() {
      let vm = this

      vm.$refs.editorContainer.addEventListener('scroll', function () {
        window.requestAnimationFrame(function () {
          vm.closePopovers()
        })
      })

      if (CONSTANTS.RIGHT_CLICK_DISABLED) {
        // do not allow right click
        document.addEventListener(
          'contextmenu',
          function (e) {
            e.preventDefault()
            vm.showMessage(400)
          },
          false
        )
      } else {
        // not being used as of now. right click is not allowed
        if (CONSTANTS.SHOW_SAMPLE_FILE_MODAL_RIGHT_CLICK) {
          document.getElementById('proseText').addEventListener(
            'contextmenu',
            function (e) {
              e.preventDefault()
              $('.ui.sampleFile.modal').modal('show')
            },
            false
          )
        }
      }
      document
        .getElementById('editor')
        .addEventListener('keydown', vm.addSpaceKeyListener)
      document.addEventListener('copy', vm.copyText)
    },
    switchOffListening() {
      let vm = this

      vm.$refs.editorContainer.removeEventListener('scroll', function () {
        window.requestAnimationFrame(function () {
          vm.closePopovers()
          if (document.getElementById('selectionToolbar')) {
            document.getElementById('selectionToolbar').style.display = 'none'
          }
        })
      })

      if (CONSTANTS.RIGHT_CLICK_DISABLED) {
        // do not allow right click
        document.removeEventListener(
          'contextmenu',
          function (e) {
            e.preventDefault()
            vm.showMessage(400)
          },
          false
        )
      } else {
        if (CONSTANTS.SHOW_SAMPLE_FILE_MODAL_RIGHT_CLICK) {
          document.getElementById('proseText').removeEventListener(
            'contextmenu',
            function (e) {
              e.preventDefault()
              $('.ui.sampleFile.modal').modal('show')
            },
            false
          )
        }
      }
      try {
        document
          .getElementById('editor')
          .removeEventListener('keydown', vm.addSpaceKeyListener)
      } catch (err) {
        console.log('err', err)
      }
      document.removeEventListener('copy', vm.copyText)
    },
    closePopovers () {
      store.state.editor.addActionPopoverConfig.show = false
      store.state.editor.labelPopoverConfig.show = false
      store.dispatch('modal/closePopovers')
    },
    addSpaceKeyListener(e) {
      /**
       * Edit media mode
       */
      let vm = this
      if (vm.onboardingState === 'delete' && e.key === 'Backspace') {
        this.onboardingState = ''
      }
      const modeBased = {
        32: 'toggleState'
      }
      const ACTIONS = {
        toggleState: function () {
          console.log('space pressed')
          vm.toggleState()
        }
      }
      let truth =
        document.getElementById('correctTranscriptId') ||
        store.state.editor.editMode !== 'editMedia'
      if (!truth && !e.shiftKey && e.keyCode in modeBased) {
        let action = modeBased[e.keyCode]
        e.preventDefault()
        ACTIONS[action](e)
        return false
      }
    },
    toggleState(computeSchedule = true) {
      store.dispatch('editor/toggleState', computeSchedule)
    },
    goToTime(time) {
      this.$refs.prose.goToTime(time)
    },
    goToPara(index) {
      this.$refs.prose.goToPara(index)
    },
    startOnboarding() {
      const vm = this
      if (this.userOnboardingState === '3') {
        this.onboardingState = 'initial'
      }
      setTimeout(() => {
        if (!vm.isPlaying) {
          vm.toggleState()
        }
      }, 500)
    },
    exitConfirm(bool) {
      if (bool) {
        this.closeModal()
        if (window.location.pathname === '/onboarding') { this.openModal({ name: 'WelcomeModal' }) }
      } else {
        this.onboardingState = ''
      }
    }
  },
  beforeDestroy() {
    if (this.podcastInfoListener) this.podcastInfoListener.off()
    if (myProseEditor) myProseEditor.stopAudioPlayer()
    store.commit('editor/setIsDemo', false)
    store.commit('editor/reset')
    this.urlList = {}
    this.videoUrlList = {}
    this.editorInit.loadingUrlList = {}
  },
  mounted() {
    this.initPodcast()
    this.switchOnListening()
  },
  created() {
    store.commit('editor/setIsDemo', true)
  },
  watch: {
    isPlaying: function (val) {
      if (val) this.playerDirty = true
    },
    downloadedAssets: function (val, oldVal) {
      if (val && !oldVal) {
        this.fileState = {
          value: 'Done',
          type: 'success'
        }
        this.syncSubtitle()
        setTimeout(() => {
          this.fileState = null
        }, 800)
      }
      if (!val) {
        this.fileState = {
          value: 'Buffering...'
        }
      }
    },
    loading: function (val, oldVal) {
      if (val !== 'saving' && oldVal === 'saving') {
        this.fileState = {
          value: 'Saved',
          type: 'success'
        }
        this.syncSubtitle()
        setTimeout(() => {
          this.fileState = null
        }, 800)
      }
      if (val === 'saving' && oldVal !== 'saving') {
        this.fileState = {
          value: 'Saving...'
        }
      }
    },
    saving: function (val, oldVal) {
      if (!val && oldVal) {
        this.fileState = {
          value: 'Saved',
          type: 'success'
        }
        this.syncSubtitle()
        setTimeout(() => {
          this.fileState = null
        }, 800)
      }
      if (val && !oldVal) {
        this.fileState = {
          value: 'Saving...'
        }
      }
    },
    playerLoadProgress(val) {
      if (val >= 1) {
        this.loading = 'false'
        this.downloadedAssets = true
        this.startOnboarding()
      }
    },
    currentTime(val) {
      console.log('hahsddc', val)
      if (val >= 6.00 && !this.deleteStateHappened) {
        if (this.isPlaying) this.toggleState()
        this.onboardingState = 'delete'
        this.deleteStateHappened = true
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.editorModal {
  width: 800px;
  height: 530px;
  max-width: 90vw;
  position: relative;
}

.editor-loading-overlay {
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.3);
}

.playerContainer {
  display: grid;
  grid-template-columns: 0.55fr 0.45fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.thumbnail {
  min-height: 228px;
  max-height: 228px;
  background: url("../../../assets/images/audioPreview.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0px 0px 14px -3.5px rgba(0, 0, 0, 0.61);
  background-color: #fff;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 2px;
  left: 7px;
}

.custom-dot::before {
  content: "";
  display: inline-block;

  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e2462c;
}
.footerlay {
  top: -58px;
  max-height: 58px;
  min-height: 58px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) -32.48%,
    rgba(255, 255, 255, 0.91) 30.28%
  );
  pointer-events: none;
}

.footershadow {
  top: -24px;
  max-height: 82px;
  min-height: 82px;
  background: linear-gradient(
    180deg,
    rgba(0, 2, 40, 0) 11.59%,
    rgba(0, 2, 40, 0.289206) 58.49%,
    #000228 146.95%
  );
  opacity: 0.31;
  pointer-events: none;
}
</style>
