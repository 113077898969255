import CONSTANTS from '@/constants/CONSTANTS'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

export default class EditorInit {
  constructor(ownerId, isDemo, isFullScreen, podcastInfoURI, browserIs) {
    this.ownerId = ownerId
    this.isDemo = isDemo
    this.fullscreen = isFullScreen
    this.podcastInfoURI = podcastInfoURI
    this.browserIs = browserIs
    this.loadingUrlList = {}
  }

  async updateSources(sources, mediaSources, urlList) {
    const vm = this
    let tempList = []
    let tempVideoList = []
    let tempImageList = []

    sources.forEach(function (source) {
      if (
        source === 0 ||
          source === '0' ||
          source.split('#!@')[0] === 0 ||
          source.split('#!@')[0] === '0'
      ) {
        // console.warn(`source can't be 0`)
        return
      }
      if (!(source in urlList) && !(source in vm.loadingUrlList)) {
        let url = vm.getURLfromSource(source)
        console.log('CHECK HERE', source)
        let videoUrl = vm.getVideoUrlFromSource(source)

        if (url) {
          vm.loadingUrlList[source] = url
          tempList.push(url)
        }
        if (videoUrl) {
          tempVideoList.push(videoUrl)
        }
      }
    })
    mediaSources.forEach(function (source) {
      if (
        source === 0 ||
          source === '0' ||
          source.split('#!@')[0] === 0 ||
          source.split('#!@')[0] === '0'
      ) {
        // console.warn(`source can't be 0`)
        return
      }
      if (!(source in urlList) && !(source in loadingUrlList)) {
        let imageUrl = vm.getImageUrlFromSource(source)
        if (imageUrl) {
          tempImageList.push(imageUrl)
        }
      }
    })
    if (vm.isDemo) {
      let source = CONSTANTS.newDemoWithNoiseSource
      let podcastWithNoise = CONSTANTS.newDemoWithNoiseAudio
      let podcastWithNoiseVideo = CONSTANTS.newDemoWithNoiseVideo
      let audioUrl = firebase
        .storage()
        .ref(podcastWithNoise)
        .getDownloadURL()
        .then(function (url) {
          return {
            source: source,
            url: url
          }
        })
      tempList.push(audioUrl)

      let videoUrl = await firebase.storage().ref(podcastWithNoiseVideo).getDownloadURL()
      tempVideoList.push({
        source: source,
        url: videoUrl
      })
    }

    // Promise.all(tempImageList).then(function (values) {
    //   values.forEach(function (value) {
    //     if (value) {
    //       vm.$set(vm.imageUrlList, value.source, value.url)
    //     }
    //   })
    // })

    // Promise.all(tempVideoList).then(function (values) {
    //   const validValues = values.filter((t) => Boolean(t))
    //   validValues.forEach(function (value) {
    //     vm.$set(vm.videoUrlList, value.source, value.url)
    //   })
    // })
    // Promise.all(tempList).then(function (values) {
    //   values.forEach(function (value) {
    //     if (value) vm.$set(vm.urlList, value.source, value.url)
    //   })
    // })

    const imageValues = await Promise.all(tempImageList)
    const videoValues = await Promise.all(tempVideoList)
    const values = await Promise.all(tempList)
    const validVideoValues = videoValues.filter((t) => Boolean(t))

    return {
      imageSources: imageValues,
      videoSources: validVideoValues,
      sources: values
    }
  }

  async getVideoUrlFromSource(source) {
    // console.log('ssssssssss mp4', source)
    /**
     * Get path of video files based on source
     */
    // console.log('source is', source)
    const vm = this
    let [resourceid, ownerid] = source.split('#!@')
    if (!ownerid) {
      ownerid = this.ownerId
    }
    let path
    if (resourceid.startsWith('m-')) {
      // return null
      let contentType = null
      path = `podcast_audio/${ownerid}/uploaded_media/${resourceid}`
      await firebase
        .storage()
        .ref(path)
        .getMetadata()
        .then((metadata) => {
          // console.log('path metadata', metadata)
          contentType = metadata.contentType
        })
        .catch((error) => {
          console.log('path metadata error', error)
        })
      if (contentType !== 'video/mp4') {
        return null
      }
      // console.log('path', path)
    } else {
      path = `podcast_audio/${ownerid}/${resourceid}/video${
        this.fullscreen ? '_r' : ''
      }.mp4`
    }
    try {
      return firebase
        .storage()
        .ref(path)
        .getDownloadURL()
        .then(function (url) {
          // console.log('ssssssssss mp4', {
          //   source: source,
          //   url: url
          // })
          return {
            source: source,
            url: url
          }
        })
        .catch(function (err) {
          console.error('Storage Error:', err)
          if (!resourceid.startsWith('m-') && vm.fullscreen) {
            path = `podcast_audio/${ownerid}/${resourceid}/video.mp4`
          }
          return firebase
            .storage()
            .ref(path)
            .getDownloadURL()
            .then(function (url) {
              return {
                source: source,
                url: url
              }
            })
            .catch(function (err) {
              console.error('Storage Error:', err)
              return null
            })
        })
    } catch (err) {
      console.log('Storage Error:', err)
      return null
    }
  }

  getImageUrlFromSource(source) {
    // console.log('ssssssssss mp4', source)
    /**
     * Get path of video files based on source
     */
    // console.log('source is', source)
    let [resourceid, ownerid] = source.split('#!@')
    if (!ownerid) {
      ownerid = this.ownerId
    }
    let path
    if (resourceid.startsWith('i-')) {
      path = `podcast_audio/${ownerid}/uploaded_media/${resourceid}`

      try {
        return firebase
          .storage()
          .ref(path)
          .getDownloadURL()
          .then(function (url) {
            // console.log('ssssssssss mp4', {
            //   source: source,
            //   url: url
            // })
            return {
              source: source,
              url: url
            }
          })
          .catch(function (err) {
            console.log('Storage Error:', err)
            return null
          })
      } catch (err) {
        console.log('Storage Error:', err)
        return null
      }
    }
  }

  getURLfromSource(source) {
    /**
     * Get path of audio files based on source
     */
    // console.log('source is', source)
    let [resourceid, ownerid] = source.split('#!@')
    if (!ownerid) {
      ownerid = this.ownerId
    }
    let path, path1, path2, path3
    if (resourceid.startsWith('m-')) {
      path = `podcast_audio/${ownerid}/uploaded_media/${resourceid}.wav`
      path1 = `podcast_audio/${ownerid}/uploaded_media/${resourceid}.mp3`
      path2 = `podcast_audio/${ownerid}/uploaded_media/${resourceid}.flac`
      path3 = `podcast_audio/${ownerid}/uploaded_media/${resourceid}`
    } else {
      if (this.browserIs === 'safari') {
        path = `podcast_audio/${ownerid}/${resourceid}/audio.mp3`
        path1 = `podcast_audio/${ownerid}/${resourceid}/audio.flac`
        path2 = `podcast_audio/${ownerid}/${resourceid}/audio.wav`
        path3 = this.podcastInfoURI
      } else {
        path = `podcast_audio/${ownerid}/${resourceid}/audio.ogg`
        path1 = `podcast_audio/${ownerid}/${resourceid}/audio.mp3`
        path2 = `podcast_audio/${ownerid}/${resourceid}/audio.flac`
        path3 = this.podcastInfoURI
      }
    }
    return firebase
      .storage()
      .ref(path)
      .getDownloadURL()
      .then(function (url) {
        return {
          source: source,
          url: url
        }
      })
      .catch(function (err) {
        console.log(`Not found at ${path}`, err)
        return firebase
          .storage()
          .ref(path1)
          .getDownloadURL()
          .then(function (url) {
            return {
              source: source,
              url: url
            }
          })
          .catch(function (err) {
            console.log(`Not found at ${path1}`, err)
            return firebase
              .storage()
              .ref(path2)
              .getDownloadURL()
              .then(function (url) {
                return {
                  source: source,
                  url: url
                }
              })
              .catch(function (err) {
                console.log(`Not found at ${path2}`, err)
                return firebase
                  .storage()
                  .ref(path3)
                  .getDownloadURL()
                  .then(function (url) {
                    return {
                      source: source,
                      url: url
                    }
                  })
                  .catch(function (err) {
                    console.log('Storage Error:', source, path3, err)
                  })
              })
          })
      })
  }
}
