<template>
  <Modal :closePosition="'hidden'" :disableCloseOnClickAway="true">
    <div class="w-430 flex flex-col items-center p-11">
      <img src="../../../assets/images/logoOrange.svg" class="w-s152 h-auto" alt="Spext Logo" />
      <div class="flex flex-col items-center py-6 font-h text-3xl font-semibold">
        <h2 class="text-primary ">Welcome {{username}}!</h2>
        <h2 class="text-primary-600">To begin,</h2>
      </div>
      <Button @click="openUploadModal" classname="addRecordingButton mb-6">
        <span class="font-ui font-medium text-xl text-white mr-2" >ADD A RECORDING</span>
        <img src="../../../assets/icons/onboardingUpload.svg" alt="upload" />
      </Button>
      <p class="font-ui text-sm text-primary-500 text-center mb-8">
        Upload a file from your computer <strong>or</strong> <br/> Paste a link (YouTube, Spotify, Loom etc.)
      </p>
      <p class="w-full font-ui text-base text-center border-t-4 border-primary-200 pt-6">No recording? Try a <strong>sample file <u @click="openSampleModal" class="cursor-pointer">here</u></strong></p>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WelcomeModal',
  components: { Modal, Button },
  methods: {
    ...mapActions({
      closeModal: 'dialogs/closeModal',
      openModal: 'dialogs/openModal'
    }),
    openUploadModal() {
      this.closeModal()
      this.$parent.$parent.showUploadModal(true)
    },
    openSampleModal() {
      this.closeModal()
      this.openModal({ name: 'EditorModal' })
    }
  },
  computed: {
    ...mapGetters({
      user: 'app/user'
    }),
    username() {
      if (!this.user || !this.user.displayName) return ''
      return this.user.displayName.split(' ')[0]
    }
  }
}
</script>

<style>
  .w-430 {
    width: 430px;
    max-width: 430px;
  }

  .addRecordingButton {
    padding: 20px 32px !important;
  }
</style>
